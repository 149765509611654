/** @jsx jsx */

import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import { Container } from '../components/ui';

const MdTemplate = ({ data }) => {
  return (
    <Layout sticky={true}>
      <HelmetDatoCms seo={data.datoCmsPage.seoMetaTags} />

      <Container pt={[108, 160]}>
        <div
          sx={theme => ({
            mx: 3,
            paddingTop: theme.space[5],
            paddingBottom: theme.space[5],

            '&>:first-of-type': {
              marginTop: '0'
            },

            'h1, h2, h3, strong, th': {
              color: 'black100',
              fontWeight: 600
            },

            'h1, h2': {
              mt: 5,
              mb: 4
            },

            h1: {
              fontSize: 5
            },

            h2: {
              fontSize: 4
            },

            h3: {
              fontSize: 3,
              mt: 4,
              mb: 2
            },

            'p, ul, ol': {
              mb: 2
            },

            'ol, ul': {
              pl: 4
            },

            table: {
              width: '100%'
            },

            a: {
              color: 'primary'
            }
          })}
          dangerouslySetInnerHTML={{
            __html: data.datoCmsPage.contentNode.childMarkdownRemark.html
          }}
        />
      </Container>
    </Layout>
  );
};

export default MdTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      id
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
